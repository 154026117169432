import { combineReducers } from "redux";
import { typeList } from "./reducers/typeList";
import { eraList } from "./reducers/eraList";
import { categorieList } from "./reducers/categorieList";
import { cityList } from "./reducers/cityList";

const rootReducer = combineReducers({
  typeList,
  eraList,
  categorieList,
  cityList,
});

export default rootReducer;
